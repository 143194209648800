import React, {useEffect, useState} from "react";
import {getAirlines, postLcpValidity, postPrioritizeNulls, postSchedulingEnabled} from "../../services/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRadiation} from "@fortawesome/free-solid-svg-icons";
import {Alert} from "react-bootstrap";


function EmergencyPage() {
    const [airlines, setAirlines] = useState([]);
    const [alert, setAlert] = useState(false);
    const [selectedAirline, setSelectedAirline] = useState("");
    const [lcpValidity, setLcpValidity] = useState(240);
    const [campaignCode, setCampaignCode] = useState("");
    const [schedulingEnabled, setSchedulingEnabled] = useState(undefined);

    const fetchAirlines = async () => {
        try {
            const response = await getAirlines();
            setAirlines(response.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAirlines();
    }, []);

    useEffect(() => {
        if (selectedAirline) {
            let airline = airlines.find(airline => {
                return airline.id == selectedAirline
            });
            setLcpValidity(airline.lcp_validity);
            setSchedulingEnabled(airline.scheduling_enabled);
        } else {
            setLcpValidity(240);
            setSchedulingEnabled(undefined);
        }
    }, [selectedAirline]);

    var disabledSchedulingAirlines = airlines.filter((airline) => !airline.scheduling_enabled);
    let schedulingCard;
    if (schedulingEnabled === undefined) {
        if (disabledSchedulingAirlines.length) {
            schedulingCard = <span>Scheduling disabled for:
                <ul>
                    {disabledSchedulingAirlines.map((airline) => {
                        return <li key={airline.id}>{airline.name}</li>
                    })
                    }
                </ul>
            </span>
        } else {
            schedulingCard = <span>Scheduling is enabled for all airlines</span>
        }
    } else {
        schedulingCard = <span>
            Scheduling is {schedulingEnabled ? "enabled" : "disabled"}
        </span>
    }


    return <main>

        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container">
                <div className="page-header-content pt-4">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto mt-4">
                            <h1 className="page-header-title">
                                <div className="page-header-icon">
                                    <FontAwesomeIcon icon={faRadiation}/>
                                </div>
                                Emergency Panel
                            </h1>
                            <div className="page-header-subtitle">Use this carefully and only if something breaks!
                            </div>
                        </div>
                        <div className="col-12 col-xl-auto mt-4">With great powers comes great responsibility</div>
                    </div>
                </div>
            </div>
        </header>
        <div className="container mt-n10">
            <div className="row">
                <Alert show={alert} onClose={() => setAlert(false)} variant="warning" dismissible>
                    <p>{alert}</p>
                </Alert>
            </div>
            <div className="row">
                <div className="col-xxl-4 col-xl-12 mb-4">
                    <div className="card">
                        <div className="card-header">Airline</div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="select-airline">Select an Airline or apply to all airlines</label>
                                <select
                                    className="form-control"
                                    id="select-airline"
                                    value={selectedAirline}
                                    onChange={
                                        (event) => {
                                            setSelectedAirline(event.target.value);
                                        }
                                    }
                                >
                                    <option value="">All airlines</option>
                                    {
                                        airlines.map((airline) => {
                                            return <option key={airline.id} value={airline.id}>{airline.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-12 mb-4">
                    <div className="card card-header-actions">
                        <div className="card-header">
                            LCP validity
                            <button className="btn btn-danger btn-sm" onClick={async (event) => {
                                await postLcpValidity(selectedAirline, lcpValidity);
                                await fetchAirlines();
                                setAlert(`LCP Validity was set to ${lcpValidity}.`);
                            }}>Set</button>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="lcp-validity">LCP Validity (in minutes)</label>
                                <input
                                    className="form-control"
                                    id="lcp-validity"
                                    type="number"
                                    min="1"
                                    value={lcpValidity}
                                    onChange={
                                        (event) => {
                                            setLcpValidity(event.target.value);
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-12 mb-4">
                    <div className="card card-header-actions mx-auto">
                        <div className="card-header">
                            Scheduling
                            <div>
                                <button className="btn btn-success btn-sm" onClick={async (event) => {
                                    await postSchedulingEnabled(selectedAirline, true);
                                    await fetchAirlines();
                                    setSchedulingEnabled(true);
                                    setAlert(`Scheduling has been enabled.`);
                                }}>Enable
                                </button>
                                &nbsp;
                                <button className="btn btn-danger btn-sm" onClick={async (event) => {
                                    await postSchedulingEnabled(selectedAirline, false);
                                    await fetchAirlines();
                                    setSchedulingEnabled(false);
                                    setAlert(`Scheduling has been disabled.`);
                                }}>Disable
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            {schedulingCard}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-4 col-xl-12 mb-4">
                    <div className="card card-header-actions">
                        <div className="card-header">
                            Prioritize Null Routes
                            <button className="btn btn-danger btn-sm" onClick={async (event) => {
                                await postPrioritizeNulls(campaignCode ? null : selectedAirline, campaignCode);
                                setAlert(`Null routes prioritized.`);
                            }}>Prioritize</button>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                You can prioritize all null routes for selected airline (or all airlines).
                                <label htmlFor="campaign-code">
                                    You can also prioritize null routes within given campaign:
                                </label>
                                <input
                                    className="form-control"
                                    id="campaign-code"
                                    placeholder="Campaign Code"
                                    value={campaignCode}
                                    onChange={
                                        (event) => {
                                            setCampaignCode(event.target.value);
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
}

export default EmergencyPage;