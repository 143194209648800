import axios from 'axios';
import { auth } from "./auth";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(function (config) {
    if (auth.isLoggedIn()) {
        config.headers.Authorization = `Token ${auth.getToken()}`;
    }

    return config;
}, function (err) {
    return Promise.reject(err);
});

export function login(email, password) {
    return api.post(`/api/auth/login`, { username: email, password });
}

export function getAirlines() {
    return api.get(`/api/airlines`);
}

export function postLcpValidity(airline, validity) {
    return api.post("/api/monitoring/set_validity", {airline: airline || null, lcp_validity: validity})
}

export function postSchedulingEnabled(airline, enabled) {
    return api.post("/api/monitoring/set_scheduling_enabled", {airline: airline || null, scheduling_enabled: enabled})
}

export function postPrioritizeNulls(airline, campaign_code) {
    return api.post("/api/monitoring/prioritize_nulls", {airline: airline || null, campaign_code: campaign_code || null})
}
