import React from "react";
import { Button, Form } from "react-bootstrap";
import { login } from "../../services/api";
import { auth } from "../../services/auth";
import { withRouter } from "react-router-dom";
import logo from "../../assets/img/logo.png";

class LoginPage extends React.Component {
    state = {
        email: "",
        password: "",
        errors: {},
        inProgress: false,
    }

    login(event) {
        event.preventDefault();
        this.setState({ inProgress: true });
        login(this.state.email, this.state.password)
            .then((response) => {
                auth.login(response.data.token_data.key);
                this.props.history.push("/");
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ errors: error.response.data })
                }
                this.setState({ inProgress: false })
            });
    }

    render() {
        return <div className="bg-primary">
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header justify-content-center">
                                            <h3 className="font-weight-light my-4"><img src={logo} alt="" style={{
                                                height: 30,
                                                marginTop: -5
                                            }} /> xCheck Dashboard</h3>
                                        </div>
                                        <div className="card-body">
                                            <Form onSubmit={(event) => this.login(event)}>
                                                <Form.Group>
                                                    <label className="small mb-1"
                                                           htmlFor="inputEmailAddress">Email</label>
                                                    <Form.Control type="email"
                                                                  className="py-4"
                                                                  value={this.state.email}
                                                                  onChange={(event) => this.setState({ email: event.target.value })}
                                                                  isInvalid={this.state.errors.username || !!this.state.errors.non_field_errors} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <label className="small mb-1"
                                                           htmlFor="inputPassword">Password</label>
                                                    <Form.Control type="password"
                                                                  className="py-4"
                                                                  value={this.state.password}
                                                                  onChange={(event) => this.setState({ password: event.target.value })}
                                                                  isInvalid={this.state.errors.password || !!this.state.errors.non_field_errors} />
                                                </Form.Group>
                                                <div
                                                    className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                                    <Button type="submit"
                                                            disabled={this.state.inProgress}>Login</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    }
}

export default withRouter(LoginPage);
