import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import LoginPage from "../LoginPage";

export default function NonAuthenticatedPagesSection() {
    let match = useRouteMatch();
    return <div className="initial-page">
        <div className="initial-page__content">
            <Switch>
                <Route path={match.path}>
                    <LoginPage />
                </Route>
            </Switch>
        </div>
    </div>;
}
