import React from "react";
import { Link, Switch, withRouter } from "react-router-dom";
import APIsListPage from "../APIsListPage";
import { LogOut, Menu } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug, faRadiation } from "@fortawesome/free-solid-svg-icons";
import PrivateRoute from "../../elements/PrivateRoute";
import { auth } from "../../../services/auth";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import logo from "../../../assets/img/logo.png"
import EmergencyPage from "../EmergencyPage";

class AuthenticatedPagesSection extends React.Component {

    state = {
         sidenavToggled: false
    }

    logout() {
        auth.logout();
        this.props.history.push("/login");
    }

    render() {
        return <div className={classNames("nav-fixed", {"sidenav-toggled": this.state.sidenavToggled})}>
            <nav className="topnav navbar navbar-expand shadow navbar-light bg-white" id="sidenavAccordion">
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="" style={{height: 30, marginTop: -5, marginRight: 5}}/>
                    xCheck Dashboard
                </Link>
                <Button className="btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" onClick={() => this.setState({sidenavToggled: !this.state.sidenavToggled})}>
                    <Menu />
                </Button>
                <ul className="navbar-nav align-items-center ml-auto">
                    <li className="nav-item">
                        <Button className="btn-icon btn-transparent-dark" onClick={() => this.logout()}><LogOut /></Button>
                    </li>
                </ul>
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav className="sidenav shadow-right sidenav-light">
                        <div className="sidenav-menu">
                            <div className="nav accordion" id="accordionSidenav">
                                <div className="sidenav-menu-heading">Core</div>
                                <Link className="nav-link" to="/">
                                    <div className="nav-link-icon"><FontAwesomeIcon icon={faPlug} /></div>
                                    APIs
                                </Link>
                                <Link className="nav-link" to="/emergency">
                                    <div className="nav-link-icon"><FontAwesomeIcon icon={faRadiation} /></div>
                                    Emergency Panel
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <Switch>
                            <PrivateRoute exact path="/">
                                <APIsListPage />
                            </PrivateRoute>
                            <PrivateRoute exact path="/emergency">
                                <EmergencyPage />
                            </PrivateRoute>
                        </Switch>
                    </main>
                </div>
            </div>
        </div>
    }
}

export default withRouter(AuthenticatedPagesSection);
