import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NonAuthenticatedPagesSection from "./components/pages/sections/NonAuthenticatedPagesSection";
import AuthenticatedPagesSection from "./components/pages/sections/AuthenticatedPagesSection";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/login">
                    <NonAuthenticatedPagesSection />
                </Route>
                <Route path="/">
                    <AuthenticatedPagesSection />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
